exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-be-part-tsx": () => import("./../../../src/pages/be-part.tsx" /* webpackChunkName: "component---src-pages-be-part-tsx" */),
  "component---src-pages-bgtest-tsx": () => import("./../../../src/pages/bgtest.tsx" /* webpackChunkName: "component---src-pages-bgtest-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-team-tsx": () => import("./../../../src/pages/our-team.tsx" /* webpackChunkName: "component---src-pages-our-team-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-why-us-tsx": () => import("./../../../src/pages/why-us.tsx" /* webpackChunkName: "component---src-pages-why-us-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-using-dsg-tsx": () => import("./../../../src/templates/using-dsg.tsx" /* webpackChunkName: "component---src-templates-using-dsg-tsx" */)
}

